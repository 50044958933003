@import 'reset.css';
@import '../../node_modules/basscss-margin/css/margin.css';
@import '../../node_modules/basscss-padding/css/padding.css';
@import '../../node_modules/basscss-typography/css/typography.css';
@import '../../node_modules/basscss-layout/css/layout.css';
@import '../../node_modules/basscss-border/css/border.css';
@import '../../bower_components/slick-carousel/slick/slick.css';
@import 'lightbox.css';
@import 'slider.css';

/* Fonts */

@font-face {
  font-family: 'luloone_bold';
  src: url('../fonts/lulo-one-bold-webfont.woff2') format('woff2'),
    url('../fonts/lulo-one-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'luloone';
  src: url('../fonts/lulo-one-webfont.woff2') format('woff2'),
    url('../fonts/lulo-one-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* Layout */

*,
*:before,
*:after {
  box-sizing: border-box;
}

:root {
  --red: #ef4235;
  --black: #273032;
  --ltgrey: #daebf2;
  --dkgrey: #9dbcc5;
  --white: #ffffff;

  --lulo: 'luloone';
  --lulobold: 'luloone_bold';
  --sans-serif: -apple-system, BlinkMacSystemFont, 'avenir next', avenir,
    helvetica, 'helvetica neue', Ubuntu, 'segoe ui', arial, sans-serif;
}

.overflow-auto {
  overflow: auto;
}

/* Colors */

.red {
  color: var(--red);
}

.black {
  color: var(--black);
}

.ltgrey {
  color: var(--ltgrey);
}

.dkgrey {
  color: var(--dkgrey);
}

.white {
  color: var(--white);
}

.bg--red {
  background-color: var(--red);
}

.bg--black {
  background-color: var(--black);
}

.bg--ltgrey {
  background-color: var(--ltgrey);
}

.bg--dkgrey {
  background-color: var(--dkgrey);
}

.bg--red--transparent {
  background-color: rgba(var(--red), 0.8);
}

.bg--ltgrey--transparent {
  background-color: rgba(var(--ltgrey), 0.5);
}

.border {
  border-color: rgba(var(--ltgrey), 0.3);
}

/* Fonts */

.sans-serif {
  font-family: var(--sans-serif);
}

.light {
  font-weight: 300;
}
.bold {
  font-family: var(--lulobold);
}
.huge {
  font-size: 32px;
}
.medium {
  font-size: 18px;
}
.small {
  font-size: 14px;
}

@media (min-width: 767px) {
  .medium {
    font-size: 21px;
  }
  .huge {
    font-size: 40px;
  }
}

@media (min-width: 992px) {
  .huge {
    font-size: 56px;
  }
}

/* Gallery */

img {
  max-width: 100%;
  vertical-align: middle;
}

.gallery img {
  width: 25%;
  float: left;
}

/* Site Header */

.site-header {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
}

.bg--home .site-header {
  background-image: url('../images/hero-image--home.jpg');
}
.bg--about .site-header {
  background-image: url('../images/hero-image--about.jpg');
}
.bg--gallery .site-header {
  background-image: url('../images/hero-image--gallery.jpg');
}
.bg--contact .site-header {
  background-image: url('../images/hero-image--contact.jpg');
}

/* Main Nav */

.l-main-nav__link {
  border-bottom: 1px solid rgba(var(--dkgrey), 0.3);
  width: 100%;
}

@media (min-width: 768px) {
  .l-main-nav__link {
    width: 33.333%;
    padding: 4rem 1rem;
    border-top: none;
    border-bottom: none;
  }

  .l-main-nav__link {
    border-right: 1px solid rgba(var(--dkgrey), 0.3);
  }

  .l-main-nav__link:last-child {
    border-right: none;
  }
}

/* Footer */

.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 767px) {
  .flex {
    flex-direction: row;
    justify-content: center;
    align-content: stretch;
  }
}
