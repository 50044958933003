.testimonials {
    overflow: hidden;
}

.slick-next,
.slick-prev {
    margin: 1em;
    width: 32px;
    height: 32px;
    border: none;
    text-indent: -9999px;
    cursor: pointer;
}

.slick-next {
	background: url('../images/icon--next.svg') center center no-repeat;
}

.slick-prev {
	background: url('../images/icon--prev.svg') center center no-repeat;
}