@import '../../bower_components/jsonlylightbox/css/lightbox.css';

.jslghtbx-thmb {
	margin: 0;
	padding: 0;
	box-shadow: none;
}

.jslghtbx-thmb:hover {
	box-shadow: none;
}

.jslghtbx-contentwrapper > img {
	padding: 0;
}

.jslghtbx-next,
.jslghtbx-prev,
.jslghtbx-no-img {
	border-top: none;
	border-right: none;
	border-bottom: none;
	border-left: none;
	width: 32px;
	height: 32px;
	background-size: contain;
}

.jslghtbx-next {
	background: url('../images/icon--next.svg') center center no-repeat;
}

.jslghtbx-prev {
	background: url('../images/icon--prev.svg') center center no-repeat;
}

.jslghtbx-next.jslghtbx-no-img,
.jslghtbx-prev.jslghtbx-no-img {
	border-top: none;
	border-right: none;
	border-bottom: none;
	border-left: none;
}

.jslghtbx-close {
	width: 32px;
	height: 32px;
	background: url('../images/icon--gallery-close.svg') center center no-repeat;
	background-size: contain;
	text-indent: -9999em;
}